#contact {
  width: 100%;
  height: 100%;
  background-color: #000;
  padding:0px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 50px;
}

.section-header {
  text-align: center;
  margin: 0 auto;
  padding: 40px 0;
  font: 300 48px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 6px;
}

.contact-wrapper {
  display: flex;
  flex-direction: row;
justify-content: space-evenly;
  margin: 0;
  padding: 20px;
  position: relative;

flex-wrap: wrap;
align-items: center;
}

/* Left contact page */
#contact .form-horizontal {
  max-width: 500px;

  font-weight: 400;
  margin-right: 20px;
  font-size: 32px;
}


#contact .form-control1 {
  width: 100%;
  font-size: 20px;
  background-color: #000;
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 15px; /* Add space between form elements */
  padding: 13px;
 padding-right: 110px;
 border: 2px solid aliceblue;
 padding-bottom: 20px;
 border-radius: none;
}

#contact .form-control2 {
  width: 85%;
  font-size: 20px;
  background-color: #000;
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 15px; /* Add space between form elements */
  padding: 13px;
 padding-right: 110px;
 border: 2px solid aliceblue;
 padding-bottom: 20px;
 border-radius: none;
 margin-left: 10px;
}
#contact .send-button {
  height: 50px;
  width: 113%;
  padding-left: 0px;
  text-align: center;
  align-items: center;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  border-radius: none;
  color:white;
  font-size: 22px;
  background-color: transparent;
  border: 1px solid aliceblue;
  margin-left: 10px;
}

#contact .alt-send-button {
  width: 100%;
  height: 20px;
  text-align: center;
  padding-right: 50px;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

#contact .send-text {
  display: block;
  margin-top: 10px;
 font-size: 18px;

  letter-spacing: 2px;
  background-color:transparent;
  font-weight: 200;
  color: white;
}


#contact .alt-send-button:hover {
  transform: translate3d(0px, -30px, 0px);
}

/* Begin Right Contact Page */
#contact .direct-contact-container {
  max-width: 600px;
  padding-top: 50px;
  padding-right: 100px;
  padding-left: 30px;
}


#contact .contact-list {
  list-style-type: none;
  margin-left: 0; 
 
}

#contact .list-item {
  line-height: 2; /* Adjust line height */
  color: #aaa;
  display: flex;
  padding: 10px;
}

#contact .list-item i{
  font-size: 20px;
}
#contact .contact-text {
 font-size: 18px;
 font-weight: 400;
  letter-spacing: 1.5px;
  color: #bbb;
 
}

#contact .place,
.phone,
.gmail {
  margin-left: 0; 
  padding-left: 20px;
  margin-top: -6px;
}

#contact .contact-text a {
  color: #bbb;
  text-decoration: none;
  transition-duration: 0.2s;
}

#contact .contact-text a:hover {
  color: #fff;
  text-decoration: none;
}

/* Social Media Icons */
#contact .social-media-list {
  position: relative;
  font-size: 20px; 
  text-align: center;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

#contact .social-media-list li a {
  color: #fff;
}

#contact .social-media-list li {
  position: relative;
  display: inline-block;
  height: 50px; 
  width: 50px; 
  margin: 10px 3px;
  line-height: 50px; 
  border-radius: 50%;
  color: #fff;
  background-color: rgb(27, 27, 27);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

#contact .social-media-list li:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50px; 
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  opacity: 0;
  box-shadow: 0 0 0 1px #fff;
  transition: all 0.2s ease-in-out;
}

#contact .social-media-list li:hover {
  background-color: #fff;
}

#contact .social-media-list li:hover:after {
  opacity: 1;
  transform: scale(1.12);
  transition-timing-function: cubic-bezier(0.37, 0.74, 0.15, 1.65);
}

#contact .social-media-list li:hover a {
  color: #000;
}

#contact .copyright {
  font: 200 12px 'Oswald', sans-serif;
  color: #555;
  letter-spacing: 1px;
  text-align: center;
}

hr {
  border-color: rgba(255, 255, 255, 0.6);
  
}


/* Media Queries for Tablets */
@media only screen and (max-width: 768px) {
#contact {
  padding-top: 20px; 
  padding-bottom: 20px; 
}

.section-header {
  padding: 20px 0; 
  font-size: 32px; 
}

.contact-wrapper {
  flex-direction: column; 
  justify-content: center; 
}

/* Left contact page */
#contact .form-horizontal {
  max-width: 100%; 
  margin-right: 0;
}

#contact .form-control1 {
  font-size: 18px; 
  padding-right: 20px; 
  
}
#contact .form-control2 {
  font-size: 18px; 
  padding-right: 20px; 
  
}
#contact .send-button {
  height: 40px; 
  padding-left: 20px; 
}

#contact .alt-send-button {
  padding-right: 20px; 
}

#contact .send-text {
  font-size: 16px; 
}

/* Begin Right Contact Page */
#contact .direct-contact-container {
  max-width: 100%; 
  padding-right: 0; 
}


#contact .list-item {
  padding: 10px;
  text-align: center; 
}

#contact .list-item i {
  font-size: 24px; 
}

#contact .contact-text {
  font-size: 16px; 
  margin-left: 0;
  padding-left: 0; 
  margin-top: 0;
}

#contact .place,
.phone,
.gmail {
  padding-left: 0; 
}

/* Social Media Icons */
#contact .social-media-list {
  text-align: center; 
}

#contact .social-media-list li {
  margin: 10px 5px; 
}

#contact .social-media-list li:after {
  width: 40px; 
  height: 40px; 
  line-height: 40px; 
}

#contact .copyright {
  font-size: 10px; 
}

hr {
  border-color: rgba(255, 255, 255, 0.6);
  margin: 20px 0; 
}
}

/* Media Queries for Mobiles */
@media only screen and (max-width: 480px) {
#contact {
  padding-top: 10px; 
  padding-bottom: 10px; 
}

.section-header {
  padding: 10px 0; 
  font-size: 28px; 
}

.contact-wrapper {
  flex-direction: column; 
  justify-content: center; 
}

/* Left contact page */
#contact .form-horizontal {
  max-width: 90%; 
  margin-right: 0;
}

#contact .form-control1 {
  max-width: 100%; 
  font-size: 16px; 
  padding-right: 15px; 
}
#contact .form-control2 {
  max-width: 100%; 
  font-size: 16px; 
  padding-right: 15px; 
}
#contact .send-button {
  height: 35px; 
  padding-left: 15px; 
}

#contact .alt-send-button {
  padding-right: 15px; 
}

#contact .send-text {
  font-size: 14px; 
}

/* Begin Right Contact Page */
#contact .direct-contact-container {
  max-width: 100%; 
  padding-right: 0; 
}


#contact .list-item {
  padding: 10px;
  text-align: center; 
}

#contact .list-item i {
  font-size: 20px; 
}

#contact .contact-text {
  font-size: 14px; 
  margin-left: 0; 
  padding-left: 0; 
  margin-top: 0;
}

#contact .place,
.phone,
.gmail {
  padding-left: 0; 
}

/* Social Media Icons */
#contact .social-media-list {
  text-align: center; 
}

#contact .social-media-list li {
  margin: 10px 5px; 
}

#contact .social-media-list li:after {
  width: 30px; 
  height: 30px; 
  line-height: 30px; 
}

#contact .copyright {
  font-size: 10px; 
}

hr {
  border-color: rgba(255, 255, 255, 0.6);
  margin: 10px 0; 
}
}