 
 div .Courses{
    text-align: start;
    padding: 20px;
   background-image:url("/public/Images/BG-imgs/Bg-Body.png");
    height: 80vh;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    padding-top: 35px;
    margin-bottom: 50px;
   opacity: .9;
   padding-left: 80px;
   padding-right: 50px;
  }

  
  
  .Courses h1 {
    font-size: 4.6em;
    color: white;
    margin-bottom: 20px;
    padding-top: 60px;
   
    letter-spacing: 2px;
    font-weight: 600;
  }
  
  .Courses a {
   color:white;
    font-size: 22px;
    text-decoration: none; 
  }

  .Courses h4 {
    font-size: 2.5em;
   
    margin-top: 10px;
    color: white;
    padding-top: 10px;
    font-weight: 400;
  }
  
  h4 span {
    margin: 0 10px;
    color:white;
  }

  @media only screen and (max-width: 768px) {
    .Courses {
      padding-left: 20px;
      padding-right: 20px;
    }
  
    .Courses h1 {
      font-size: 3em;
      padding-top: 30px;
    }
  
    .Courses h4 {
      font-size: 1.8em;
    }
  }
  
  /* For Mobiles */
  @media only screen and (max-width: 480px) {
    .Courses {
      padding-left: 10px;
      padding-right: 10px;
    }
  
    .Courses h1 {
      font-size: 2.5em;
      padding-top: 20px;
    }
  
    .Courses h4 {
      font-size: 1.5em;
    }
  }