/* Flex container for cards */
.card-container {
  display: flex;
  
  
 
  background-color:transparent;
  margin-top: -100px;
z-index: 1000;
padding-bottom: 50px;
margin-left: 210px;
flex-wrap: wrap;
  
font-family: "Open Sans", sans-serif;
}



/* Individual card styles */
.card {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  /* Add other styles as needed */
  width: 300px;
  height: 130px;
  
 
  margin: 20px;
  padding: 10px;
}

.card-image {
  width: 60px;
  height: 50px; 
}
.card-image img{
  padding-left: 20px;
}

.card-content {
 text-align: start;
  margin-left: 10px;
  margin-top: 20px;
}

.card-title {
  font-size: 1.0rem;
  margin-bottom: 10px;
 
  margin-left: 25px;
}

.card-description {
  font-size: 1rem;
  color: #3a3a3a;
  
}

/* Media Queries for Tablets */
@media only screen and (max-width: 768px) {
.card-container {
  margin-left: 20px; /* Adjust as needed */
  margin-top: 0px;
}

  .card {
      width: 100%; /* Take up the full width of the screen */
      margin: 10px 0; /* Adjust as needed */
  }

  .card-image {
      width: 40px; /* Adjust image size for tablets */
      height: 30px;
  }

  .card-title {
      margin-left: 20px; /* Adjust as needed */
  }
}

/* Media Queries for Mobiles */
@media only screen and (max-width: 480px) {
  .card-container {
      margin-left: 10px; /* Adjust as needed */
      margin-top: 0px;
  }

  .card {
      width: 100%; /* Take up the full width of the screen */
      margin: 10px 0; /* Adjust as needed */
  }

  .card-image {
      width: 30px; /* Adjust image size for mobiles */
      height: 20px;
  }

  .card-title {
      margin-left: 10px; /* Adjust as needed */
      font-size: 0.9rem; /* Adjust title font size for mobiles */
  }

  .card-description {
      font-size: 0.9rem; /* Adjust description font size for mobiles */
  }
}