.container-course {
  display: flex;

 
  padding: 20px;
  flex-wrap: wrap;
  background-color: aliceblue;
}

.container-course .left {
  text-align: start;
  margin-left: 100px;

}

.container-course .right {
 margin-left: 20px;
 padding-top: 80px;
 padding-left: 200px;
}

.container-course .right img {
  width: 120%; 
  max-width: 570px;
  height: 390px;

}

.container-course h2{
  color: #f1440b;
}
.container-course p {
 
  color:black;
  font-size: 19px;
  line-height: 30px;
  margin-bottom: 25px;
  font-weight: normal;
  width: 500px;
  margin-left: -10px;
  
}
.container-course h1{
  margin-bottom: 10px; /* Add spacing between h2 and p */
 
  font-weight: 500;
  font-size: 2.8em;
  line-height: 1.1em;
  letter-spacing: 1px;
  color:black;
  padding-left: 0px;
  width: 490px;
}

.container-course .slide {
  font-style: cursive;
  background-color:#f1440b;
  color: white;
  border: none;
  padding: 13px 20px;
  cursor: pointer;
  border-radius: 30px;
  margin-bottom: 30px;
}


/* Media queries for tablets */
@media screen and (max-width: 1024px) {
  .container-course {
    flex-direction: column;
    padding: 10px;
    text-align: center;
    padding-left: 30px;
  }

  .container-course .left,
  .container-course .right {
    margin-left: 0;
    
  }

  .container-course .right img {
    width: 100%;
    max-width: none;
    height: auto;
  }

  .container-course h1,
  .container-course p {
    width: 100%;
    padding-left: 0;
    font-size: 18px;
  }
}

/* Media queries for mobiles */
@media screen and (max-width: 767px) {
  .container-course .left,
  .container-course .right {
    margin-left: 0;
    text-align: center;
    padding-left: 0px;
    align-items: center;
  }

  .container-course .right img {
    width: 100%;
   
  }

  .container-course h1,
  .container-course p {
    width: 100%;
    padding-left: 0;
  }
}