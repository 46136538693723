/* YourComponent.css */

.container-java {
  display: flex;
 
 align-items: center;
  padding: 20px;
  background-color:rgb(49, 49, 76);
  padding-top: 50px;
  padding-bottom: 50px;
  flex-wrap: wrap;
}

.container-java .left img {
  width: 100%;
 max-width:550px;
  height:450px;
  padding-left: 50px;
  background-color: none;
 
}
.container-java .right {
  max-width: 430px; 
  
  color: white;
  margin-left: 120px;
}

.container-java .right h1 {
  margin-bottom: 10px; /* Add spacing between h2 and p */
 
  font-weight: 500;
  font-size: 2.8em;
  line-height: 1.3em;
  letter-spacing: 1px;
  color: white;
  padding-left: 0px;
 
}
.container-java .right h2{
  margin-bottom: 10px; /* Add spacing between h2 and p */
  width: 680px;
  font-weight: 500;
  font-size: 1.5em;
  line-height: 1.2em;
  letter-spacing: 1px;
  color: white;
  padding-left: 30px;
  width: 640px;
  margin-left: 50px;
}


.container-java .right  p{
 
  color: white;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 25px;
  font-weight: normal;
  width: 600px;
  text-align: start;
  margin-left: -50px;
}

/* Media Queries */
@media only screen and (max-width: 768px) {
  .container-java {
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: start;
  }

  .container-java .left img {
    width: 90%;
    max-width: none;
    height: auto;
    padding-left: 0; /* Adjusting padding for better responsiveness */
  }

  .container-java .right {
    max-width: 100%; /* Adjusted for full width on smaller screens */
    margin-left: 0; /* Removed unnecessary margin */
    text-align: start;
  }

  .container-java .right h1,
  .container-java .right h2 {
    margin-left: 0;
  }

  .container-java .right p {
    padding-left: 0; /* Adjusted padding for better responsiveness */
    width: 100%; /* Adjusted width for full width on smaller screens */
    text-align: start;
  }
}

