@media only screen and (max-width: 768px) {
    .map-container {
      margin-left: 10px; 
    }
  }

  @media only screen and (max-width: 480px) {
    .map-container {
      margin-left: 10px; 
    }
  }