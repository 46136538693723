.container-Testing {
  display: flex;  
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color:rgb(3, 3, 49);
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: start;
  flex-wrap: wrap;
}

.container-Testing .left img {
 
  width: 450px; 
  height: 370px;
  padding-left: 0px;
  margin-right: 150px;
}

.container-Testing .right {
  padding-top: 0px;
  max-width: 450px; 
  
  color: white;
  padding-left: 0;
  margin-left: 50px;
}

.container-Testing .right h2 {
  margin-bottom: 10px;
  font-weight: 500;
  padding-top: 80px;
  font-size: 32px;
  line-height: 1.2em;
  letter-spacing: 1px;
  color: white;
  padding-left: 0px;
  width: auto;
  margin-left: 0px;
   width: 550px;
}
.container-Testing .right h3{
  color: white;
  margin-left: 80px;
  width: 560px;
}
.container-Testing .right  p{
 
  color: white;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 25px;
  font-weight: normal;
  width: 540px;
  text-align: start;
  margin-left: 0px;
}

strong{
color:#fe5900;
}
/* Media Queries */
@media only screen and (max-width: 768px) {
  .container-Testing {
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .container-Testing .left img {
    width: 100%;
    height: auto;
    margin-right: 0;
  }

  .container-Testing .right {
    max-width: 100%;
    margin-left: 0;
    padding-left: 0;
    text-align: start;
  }

  .container-Testing .right h2 {
    padding-top: 40px;
    width: 100%; /* Adjusted width for full width on smaller screens */
    margin-left: 0;
  }

  .container-Testing .right h3 {
    margin-left: 0;
    width: 100%; /* Adjusted width for full width on smaller screens */
  }

  .container-Testing .right p {
    width: 100%; /* Adjusted width for full width on smaller screens */
    margin-left: 0;
  }
}



/* Media Queries */
@media screen and (max-width: 1180px) {
.container-Testing .left img {
  width: 100%;
  height: auto;
}

.container-Testing .right {
  max-width: 100%;
  text-align: center;
  padding-left: 0;
}

.container-Testing .right h2,
.container-Testing .right h3,
.container-Testing .right p {
  width: 90%;
  margin-left: 0;
}
}

@media screen and (max-width: 820px) {
.container-Testing {
  padding-top: 30px;
  padding-bottom: 60px;
}

.container-Testing .left img {
  width: 100%;
  height: auto;
}

.container-Testing .right {
  max-width: 100%;
  text-align: center;
  padding-left: 0;
}

.container-Testing .right h2,
.container-Testing .right h3,
.container-Testing .right p {
  width: 90%;
  margin-left: 0;
}
}

