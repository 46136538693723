.container {
  display: flex;

  padding: 10px;
  background-color: aliceblue;
  padding-top: 40px;
  padding-bottom: 50px;
}
.container .left{
  padding-left: 100px;
}

.left img {
  width: 100%; 
  max-width: 520px; 
  height: 420px;
  padding-left: 0px;
}

.right1 {
  width: 550px; 
 text-align: start;
  padding-left: 200px;
}
.right1 h5{
  color: #ff7f00;
  font-size: 16px;
}

.right1 h1 {
  margin-bottom: 10px; 
 
  font-weight: 500;
  font-size: 2.8em;
  line-height: 1.3em;
  letter-spacing: 1px;
  color:black;
  width: auto;
}

.right1 p {
  
  color: black;
  font-size: 19px;
  line-height: 30px;
  margin-bottom: 25px;
  font-weight: normal;
  width: 530px;
  margin-left: -8px;
}

.slide {
  background:#ff7f00;
  color: white;
  border: none;
  padding: 14px 28px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 32px;
  margin-left: 30px;
}

button:hover {
 
  background: linear-gradient(to bottom, #2980b9, #3498db);
}



/* Media queries for tablets */
@media screen and (max-width: 1024px) {
  .container {
    flex-direction: column;
    padding: 10px;
  }
  .left img {
    width: 100%; 
    max-width: 480px; 
    height: 420px;
    padding-left: 0px;
    margin-right: 40px;
  }

  .right {
    max-width: none;
    padding-left: 0;
    text-align: start;
  }

  .right h1 {
    width: 100%;
  }

  .right p {
    width: 90%;
  }

  button {
    margin-left: 0;
  }
}

/* Media queries for mobiles */
@media screen and (max-width: 767px) {
  .left img {
    height: auto;
    width: 90%;
  }

  
.right1 {
 width: 100%;
 padding-left: 0px;
}
.right1 h5{
  color: #ff7f00;
  font-size: 16px;
}

.right1 h1 {
  margin-bottom: 10px; 
 
  font-weight: 500;
  font-size: 2.8em;
  line-height: 1.3em;
  letter-spacing: 1px;
  color:black;
  width: auto;
}

.right1 p {
  
  color: black;

  font-weight: normal;
  width: fit-content;
 
}

}

/* Media Queries */
@media screen and (max-width: 1180px) {
.container {
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.container .left {
  padding-left: 0;
}

.left img {
  margin-bottom: 20px;
}

.right {
  padding-left: 0;
}

.slide {
  margin-left: 0;
}
}

@media screen and (max-width: 820px) {
.container {
  padding: 20px;
}

.right {
  max-width: 100%;
  text-align: center;
  padding-left: 0;
}

.right h1,
.right p {
  width: 100%;
  margin-left: 0;
}

.slide {
  margin-left: 0;
}
}