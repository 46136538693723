.container2 {
  display: flex;
  align-items: center; 
  background-color: rgb(3, 3, 49);
  background-size: cover;
  padding-bottom: 160px;
  padding-top: 40px;
  flex-wrap: wrap;
}

.content {
  display: block;
  padding-top: 70px;
  text-align: start;
  align-items: center;
  margin-left: 80px;
}

.music-icon {
  margin-left: auto; 
  margin-right: 70px;
  align-items: center;
}

  .container2 .content img{
    background-color: #007bff;
    padding: 25px;
    border-radius: 48%;
  }
  
 
  

  .container2 .custom-h2 {
     /* Your desired text color */
    margin-bottom: 10px; /* Add spacing between h2 and p */
   
    font-weight: 700;
    font-size: 2.8em;
    line-height: 1.4em;
    letter-spacing: 1px;
    color: white;
    
    width: 530px;
  }
  
  /* Custom styles for p */
  .container2 .custom-p {
    
    color: white;
    font-size: 19px;
    line-height: 30px;
    margin-bottom: 15px;
    font-weight: 300;
    width: 510px;
    margin-right: 40px;
  }
  
  /* Custom styles for the "View Products" button */
  .custom-button {
    font-size: 18px;
    color: #fff;
    background:#ff7f00;
    border: none;
    padding-bottom: 40px;
    padding: 16px 29px;
    margin-left: 20px;
    border-radius: 32px;
    cursor: pointer;
    transition: background-color 0.3s ease;/* Smooth background color transition on hover */
  }
  
  .container2 .custom-button:hover {
    background-color: #8B4513; /* Brownish color on hover */
  }

  .icon-item{
    color: white;
    display: flex;
    
    font-size: 19px;
    line-height:1.5;
    
   
  }

  .icon-item p{
    color: white;
    font-size: 19px;
    
    font-weight: 400;

    width:50%;

  }

  .icon-list{
    padding-bottom: 30px;
  }
  .video-frame{
   margin-top: -500px;
   margin-right: 90px;
   width: 630px;
   height: 450px;
  }
  
/* Media Queries for Tablets */
@media only screen and (max-width: 768px) {
    .container2 {
        flex-direction: row;
        align-items: center;
        text-align: center;
        padding-left: 30px;
    }

    .container2 .content {
        margin-left: 0;
    }

    .container2 .custom-h2 {
        width: 100%;
        margin-bottom: 20px;
    }

    .container2 .custom-button {
        margin-left: 0;
        margin-top: 20px;
    }
    .music-icon{
        margin-left: 10px;
    }
    .video-frame{
      margin-top: 20px;
       margin-right: 0px;
      width: 100%;
      height: auto;
      }
}

/* Media Queries for Mobiles */
@media only screen and (max-width: 480px) {
    .container2 {
        flex-direction:column;
        align-items: start;
       text-align: start;
    }

    .container2 .content {
        margin-left: 0;
    }

    .container2 .custom-h2 {
        width: 100%;
        max-width: 95%;
        margin-bottom: 20px;
    }

    .container2 .custom-button {
        margin-left: 0;
        margin-top: 20px;
    }

    .icon-item p {
        width: 98%;
        margin-right: 10px;
    }
    .video-frame{
      margin-top: 20px;
       margin-right: 0px;
      width: 100%;
      height: auto;
      }
}

/* Media Query for screens with a width between 829px and 1180px */
@media only screen and (min-width: 829px) and (max-width: 1180px) {
  .container2 .content {
    margin-left: 40px; 
  }

  .music-icon {
    margin-right: 40px; 
  }

  .container2 .custom-h2,
  .container2 .custom-p {
    width: auto; 
  }
  .video-frame{
    width: 130%;
    max-width: 450px;
    height: 400px;  
    margin-left: -160px;
    }
}