.testimonal-slider .client {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  line-height: 1.2;
}


.testimonial-slider {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  text-align: center;
  padding: 40px 0px;
}

.testimonial-slider h6 {
  color:#d65616;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 0.5rem;
 
}

.testimonial-slider h2 {
  font-size: 28px;
  line-height: 46px;
  color:black;
  margin: 15px 0;
}
.containerTest {
  width: 87%;
  margin: 0 auto; 
}
.content-title {
  font-size: 28px;
  line-height: 46px;
}

.testimonial-item {
  padding: 0px 0px;
  position: relative;
  color:Black;
  font-weight: 300;
  padding-left: 20px;
}

.testimonial-item .testimonial-text {
 
  line-height: 2.9em;
  color: black;
  margin-bottom: 0px;
 
  font-family: "Hind", serif;
  -webkit-font-smoothing: antialiased;
  width: 93%;
  font-weight: bold;
  font-size: 21px;
  padding-top: 40px;
}

.role-text{
font-size: 18px;
font-weight: 400;
}

.slick-slide:focus, .slick-slide a {
  outline: none;
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.text-capitalize{
  color: #061941;
  font-size: 22px;
}

/* Mobile Devices */
@media only screen and (max-width: 767px) {
  .testimonial-slider h2 {
    font-size: 24px;
    line-height: 38px;
  }

  .testimonial-item {
    padding: 50px 10px;
  
  }

  .testimonial-item .testimonial-text {
    font-size: 19px;
    width: fit-content;
    font-weight: 700;
  }

  .content-title {
    font-size: 24px;
    line-height: 38px;
  }
}

/* Tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-slider h2 {
    font-size: 22px;
    line-height: 42px;
  }

  .testimonial-item {
    padding: 50px 0;
    margin-left: 10px;
  }

  .testimonial-item .testimonial-text {
    font-size: 21px;
  }

  .content-title {
    font-size: 22px;
    line-height: 42px;
  }
}