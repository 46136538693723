/* CSS for LeftContentRightImage */
.container4 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 0px;
   
  background-image: url(/public/Images/BG-imgs/Home-body.jpg);
  background-size: cover;
  object-fit: cover;

 height: 80vh;
 padding-bottom: 150px;
 
 
  }
  
  .container4 .content {
    /* Content takes up available space */
   
    display: block;
    padding-top:0px;
    text-align: start;
    padding-left: 50px;
    
  }
  
 
  

  .container4 .custom-h2 {
   
    margin-bottom: 10px;
   
    padding-top: 150px;
    font-size: 4.2em;
    line-height: 1.2em;
    letter-spacing: 2px;
    color:white;
    padding-bottom: 10px;
    font-weight: 600;
    font-family:veredana;
   
  }
  
  /* Custom styles for p */
  .container4 .custom-p {
    font-family: Georgia, serif;
    font-size: 2.2rem;
   font-weight: lighter;
    line-height: 1.5em;
    margin: 25px 0 10px 0;
    color: white;
    padding-bottom: 30px;
   margin-right: 100px;
   
  }
  
  /* Custom styles for the "View Products" button */
   .custom-button {
    font-size: 1em;
    color: #fff;
    background:#ff7f00;
    border: none;
    padding-bottom: 40px;
    padding: 16px 29px;
    margin-left: 20px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .custom-button:hover {
    background-color: #8B4513; /* Brownish color on hover */
  }


/* Media Queries for Tablets */
@media only screen and (max-width: 768px) {
  .container4 {
      flex-direction: column;
      height: auto;
      padding-bottom: 100px;
      text-align: start;
  }

  .container4 .content {
      padding-left: 40px;
      padding-right: 20px;
      text-align: center;
  }

  .container4 .custom-h2 {
      padding-top: 10px;
  }

  .container4 .custom-p {
      text-align: start;
  }

  .custom-button {
      font-size: 1.2em;
  }
}

/* Media Queries for Mobiles */
@media only screen and (max-width: 480px) {
  .container4 {
      flex-direction: column;
      height: auto;
      text-align: start;
      width:fit-content;
  }

  .container4 .content {
      padding-left: 10px;
      padding-right: 10px;
  }

  .container4 .custom-h2 {
      margin-bottom: 10px;
      padding-top: 50px; /* Adjusted padding-top for smaller screens */
      font-size: 2em;
      line-height: 1.2em;
      letter-spacing: 2px;
      padding: 20px;
      width: fit-content;
  }

  .container4 .custom-p {
      text-align: start;
      margin-right: 0; 
  }

  .custom-button {
      font-size: 1em;
  }
}

@media screen and (max-width: 1180px) {
  .container4 {
    padding-bottom: 100px; /* Adjust as needed for the smaller screen */
  }
}

@media screen and (max-width: 820px) {
  .container4 {
    flex-direction: column;
    text-align: center;
    padding: 50px 20px;
  }

  .container4 .content {
    padding-left: 0;
  }

  .custom-button {
    margin-left: 0;
  }
}