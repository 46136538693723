.navbar {
  background: aliceblue;
   height: 95px;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 1.2rem;
   font-weight: 600;
   position: sticky;
   top: 0;
   z-index: 20;
 }
 
 .nav-container {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 80px;
   max-width: 1500px;
   position: relative;
 }
 
 .nav-logo {
   color: #f5b921;
   align-items: center;
   margin-left: 20px;
   cursor: pointer;
   text-decoration: none;
   font-size: 2rem;
   flex-grow: 1;
 
   display: flex;
   align-items: center;
   justify-items: center;
 }
 
 .nav-logo img {
   display: inline-block;
   width: 14rem;
   height:auto;
   margin-left: 16px;
   border-radius: 10px;
 }
 
 .nav-menu {
   display: flex;
   list-style: none;
   text-align: center;
   margin-right: 2rem;
   margin-top: 50px;
   color: #080808;
 }
 
 .nav-links {
   color: #080808;
   text-decoration: none;
   padding: 0.5rem .8rem;
   height: 100%;
   border-bottom: 3px solid transparent;
 }
 /* .fa-code {
   margin-left: 1rem;
 } */
 
 .nav-item {
   line-height: 40px;
   margin-right: 1rem;
 }

 
 .nav-item:after {
   content: "";
   display: block;
   height: 3px;
   width: 0;
   background: transparent;
   transition: width 0.7s ease, background-color 0.5s ease;
 }
 
 .nav-item:hover:after {
   width: 100%;
   background: #f1440b;
 }
 
 .nav-item .active {
   color: #f1440b;
  
 }
 
 .Nav-icon{
   font-size: 2.3rem;
   color: #f1440b;
 }
 
 
 @media screen and (max-width: 960px) {
   .navbar{
     width: 100%;
   }
   .nav-menu {
     display: flex;
     flex-direction: column;
     width: 60%;
     border-top: 1px solid #fff;
     position: absolute;
     top: 38px;
     padding: 10px;
     left: -120%;
     opacity: 1;
     transition: all 0.5s ease;
     color: #080808;
   }
   .nav-icon {
     color: black;
   }
   
   .nav-icon .icon {
     display: inline-block;
     width: 1rem;
     height: 1rem;
     color: #080808;
   }
 
   .nav-menu.active {
     background:aliceblue;
     left: 0px;
     opacity: 1;
     transition: all 0.5s ease;
     z-index: 1;
     color: #080808;
   }

   .custom-button{
     text-decoration: none;
     font-size: 1em;
     color: #fff;
     background: #ff7f00;
     border: none;
     padding: 10px 16px;
     margin-left: 20px;
     border-radius: 32px;
     cursor: pointer;
     transition: background-color 0.3s ease; 
     margin-top: -10px;
     
   }
   
   .nav-item .active {
     color: #f1440b;
     border: none;
   }
   .nav-links {
     padding: 1.5rem;
     width: 100%;
     display: table;
   }
 
   .nav-icon {
     display: block;
     position: absolute;
     top: 50%;
     right: 0;
     transform: translate(-100%, -50%);
     width: 2rem;
     height: 2rem;
     cursor: pointer;
     color:black;
   }
  
  
  
 
   .nav-icon .icon {
     font-size: 2rem; 
    display: block;
   }
 
 }

/* Add this to your existing CSS file */
.sub-menu {
 display: none;
 position: absolute;
background: black;
 z-index: 1;
}

.nav-item:hover .sub-menu {
 display: flex;
 flex-direction: column;
}

.sub-menu .nav-item {
 margin-right: 0; /* Remove the right margin for submenu items */
}

.sub-menu .nav-links {
 padding: 1rem 2rem; /* Adjust padding for submenu links */
}

.sub-menu .nav-item:after {
 content: none; /* Remove the underline effect for submenu items */
}


@media screen and (min-width:961px){
.nav-icon{
font-size: 2rem;
 color: black;
 display: none;
}


}