.project-container {
  display: flex;
  align-items: center;
  background: aliceblue;
  padding: 80px;
 
}

.image-container {
  flex: 1;
  margin-right: 20px; /* Adjust the margin as needed */
}

.image-container img {
  max-width: 100%;
  height: auto;
  
}

.Img1{
  width: 100%;
  padding-top: 30px;
}
.content-container {
  flex: 1;
  
}
.content-container h2{
  font-size: 40px;
  font-weight: 500;
}

.content-container p{
  font-size: 21px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: .1px;

}
.content-container span{
  color:  #fe5900;
  font-size: 24px;
}


@media (max-width: 1024px) {
 
  .project-container {
    flex-direction: column;
    padding: 50px;
  }

  .image-container {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .content-container {
    margin-right: 0;
  }
  .content-container p{
    font-size: 21px;
    

  }
}

/* Media query for tablets */
@media (max-width: 768px) {
  .project-container {
    flex-direction: column;
    padding: 40px;
  }

  .image-container {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .content-container {
    margin-right: 0;
  }
}

/* Media query for mobiles */
@media (max-width: 480px) {
  .project-container {
    padding: 20px;
  }

  .content-container h2 {
    font-size: 30px;
  }

  .content-container p {
    font-size: 16px;
    
  }

  .content-container span {
    font-size: 18px;
  }
}