.team-member {
  display: inline-block;
  flex-wrap: wrap;
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  justify-content: space-evenly;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 80px;
}

.member-image {
  position: relative;
  overflow: hidden;
  border-radius: 30%;
  width: 250px; 
  height: 250px; 
  
}
.Team{
  text-align: center;
  margin-top: 90px;
}

.member-image img {
  width: 100%;
  height: 250px;
  transition: transform 0.3s ease-in-out;
  border-radius: 50%;
}
.member-image:hover{
  opacity:0.8;
  color:black
}

.social-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 30px;
}

.social-icons a {
  display: inline-block;
  margin: 0 5px;
}

.social-icons img {
  width: 30px; 
  height: 30px; 
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
}

.member-name,
.member-designation {
  margin: 10px 0;
}

.member-name {
  font-family: Verdana;
  font-size: large;
  font-weight: 900;
}

.member-designation {
  font-weight: 500;
  font-family: Times New Roman;
}

.team-member:hover .social-icons {
  opacity:1.2;
  font-size: 22px;
}

.social-icons:hover img {
  transform: scale(1.2);
}

@media only screen and (max-width: 768px) {
  .team-member {
    margin-left: 0;
  }

  .member-image {
    width: 150px; 
    height: 150px; 
  }

  .member-image img {
    height: 150px; 
  }

  .social-icons img {
    width: 20px; 
    height: 20px; 
  }
}