/* YourComponent.css */

.container-Ba {
    display: flex;
   
   align-items: center;
    padding: 20px;
    background-color:rgb(49, 49, 76);
    padding-top: 50px;
    padding-bottom: 50px;
    flex-wrap: wrap;
    
  }
  
 .container-Ba .left img {
    width: 620px; 
    height: 450px;
    padding-left: 80px;
   margin-right: 0px;
  }
  .container-Ba .right {
    padding-top: 50px;
    max-width: 430px; 
    
    color: white;
    margin-left: 50px;
  }
  
 .container-Ba .right h1 {
    margin-bottom: 10px; 
   
    font-weight: 500;
    font-size: 2.8em;
    line-height: 1.3em;
    letter-spacing: 1px;
    color: white;
    padding-left: 0px;
   
  }
  .container-Ba .right h2{
    margin-bottom: 10px; 
   
    font-weight: 500;
    font-size: 1.5em;
    line-height: 1.2em;
    letter-spacing: 1px;
    color: white;
    padding-left: 50px;
   
    margin-left: 0px;
  }

  
  .container-Ba .right  p{
   
    color: white;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 25px;
    font-weight: normal;
    width: 600px;
    text-align: start;
    
  }
 
 
  

/* Media Queries */
@media only screen and (max-width: 768px) {
    .container-Ba {
      flex-direction: column;
      padding-top: 40px;
      padding-bottom: 40px;
      text-align: start;
    }
  
    .container-Ba .left img {
      width: 100%;
      max-width: 450px;
      height: auto;
    }
  
    .container-Ba .right {
      max-width: 60%;
      margin-right: 0;
      text-align: start;
      flex-wrap: wrap;
     
    }
  
    .container-Ba .right h1,
    .container-Ba .right h2,
    .container-Ba .right h5,
    .container-Ba .right h4{
      padding-left: 0px;
      margin-left: -10px;
    }
  
    .container-Ba .right p {
        padding-left:0px;
        width: 180%;
        text-align: start;
        margin-left: -30px;
    
      }
    
    .container-Ba button {
      margin-left: 15px;
    }
  }


  /* Media Queries */
@media screen and (max-width: 1180px) {
  .container-Ba .left img {
    max-width: 100%; /* Adjust as needed for smaller screens */
  }

  .container-Ba .right {
    max-width: 100%;
    text-align: center;
    padding-left: 0;
  }

  .container-Ba .right h1,
  .container-Ba .right h2,
  .container-Ba .right p {
    width: 100%;
    margin-left: 0;
  }
}

@media screen and (max-width: 820px) {
  .container-Ba {
    padding-top: 30px;
    padding-bottom: 30px; /* Adjust as needed for smaller screens */
  }

  .container-Ba .left img {
    max-width: 100%; /* Adjust as needed for smaller screens */
  }

  .container-Ba .right {
    max-width: 100%;
    text-align: center;
    padding-left: 0;
  }

  .container-Ba .right h1,
  .container-Ba .right h2,
  .container-Ba .right p {
    width: 100%;
    margin-left: 0;
  }
}

/* Media Queries for Mobile Devices */
@media only screen and (max-width: 480px) {
  .container-Ba {
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  .container-Ba .left img {
    width: 100%;
    max-width: 100%;
    height: auto;
    padding-left: 0;
    margin-right: 0;
  }

  .container-Ba .right {
    max-width: 100%;
    margin-left: 0;
    text-align: center;
  }

  .container-Ba .right h2 {
    padding-left: 0;
    margin-left: 0;
  }

  .container-Ba .right p {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
  }
}
