.background-container {
    display: flex;
  background-color:white;
   padding: 50px;
  padding-bottom: 50px;
  padding-top: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  flex-wrap: wrap;


  }
  
  .image-containerbg {
    flex: 1; 
  }
  .image-containerbg img{
    width: 80%;
    height: auto;
  }
  
  .background-image {
    width: 100%; 
    height: auto;
  }
  
  .content-containerbg {
    flex: 1; 
    padding: 20px;
  }
  
  .content-containerbg h1{
    color:f1440b;
    font-size: 38px;
  
    padding-top: 50px;
    padding: 20px;
  }

   
  .content-containerbg p{
    color: black;
    font-size:19px;
   
    width: 450px;
    line-height: 1.5em;
   
    font-weight: 0;
    padding-bottom: 30px;
    margin-left:20px ;
    
  }


/* Media Queries for Tablets */
@media only screen and (max-width: 768px) {
  .background-container {
      padding: 0px;
      padding-bottom: 30px;
      padding-top: 70px;
  }
 

  .content-containerbg h1 {
      font-size: 30px;
  }
  .image-containerbg img{
    width: 100%;
    max-width: 450px;
    height: auto;
  }

  .content-containerbg p {
      width: 100%; /* Adjusted width for responsiveness */
  }
}

/* Media Queries for Mobiles */
@media only screen and (max-width: 480px) {
  .background-container {
      padding: 20px;
      padding-bottom: 20px;
      padding-top: 50px;
      flex-direction: column;
  }
  .image-containerbg img{
    width: 100%;
    max-width: 400px;
    height: auto;
  }

  .content-containerbg h1 {
      font-size: 24px;
  }

  .content-containerbg p {
      width: 100%; /* Adjusted width for responsiveness */
      font-size: 16px;
  }
}