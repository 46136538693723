/* SignupPopup.css */

.popup {
   
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  
  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20%;
    height:auto;
    padding: 35px;
    border-radius: 8px;
   
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    
   align-items: center;
   text-align: center;
   background: #616161;  
   background: -webkit-linear-gradient(to right, #9bc5c3, #616161);  
   background: linear-gradient(to right, #9bc5c3, #616161);  
   
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    
  }
  .social-icons1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .social-icons1 .social-login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 60%;
    transition: background-color 0.3s ease;
  }
  
  
  
  .specific-close-button {
    background-color:chocolate;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 40%;
    padding: 10px;
    transition: background-color 0.3s ease;
  }
  
 .social-icons1 i{
    font-size: 40px;
 }
  /* Add additional styling as needed */
  
  /* Media Queries for Tablets */
@media only screen and (max-width: 768px) {
    .popup-content {
      width: 50%; 
    }
  
    .social-icons1 .social-login-button,
    .specific-close-button {
      width: 80%; 
    }
  
    .social-icons1 i {
      font-size: 30px; 
    }
  }
  
  /* Media Queries for Mobiles */
  @media only screen and (max-width: 480px) {
    .popup-content {
      width: 80%; 
    }
  
    .social-icons1 .social-login-button,
    .specific-close-button {
      width: 100%; 
    }
  
    .social-icons1 i {
      font-size: 24px; 
    }
  }