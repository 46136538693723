.popup-background {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-form {
    background-image: linear-gradient(122.3deg, rgba(111, 71, 133, 1) 14.6%, rgba(232, 129, 166, 1) 29.6%, rgba(237, 237, 183, 1) 42.1%, rgba(244, 166, 215, 1) 56.7%, rgba(154, 219, 232, 1) 68.7%, rgba(238, 226, 159, 1) 84.8%);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(228, 185, 185, 0.2);
    width: 350px;
    height: auto; 
    overflow-y: auto; 
    padding-bottom: 20px;
    
  }

  
  .popup-form1 {
    background: -webkit-linear-gradient(to right, #9bc5c3, #616161);  
    background: linear-gradient(to right, #9bc5c3, #616161);  
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(228, 185, 185, 0.2);
    width: 300px;
    height: auto; 
    overflow-y: auto; 
    padding-bottom: 20px;
    
  }
  
  .popup-content h2 {
    margin-bottom: 10px;
    color: #050505;
  }
  
  .form-group {
    padding: 10px;
    display: flex;
  }
  
  .form-group input,
  .form-group textarea {
    text-align: start;
    padding: 10px;
    border-radius: 5px;
    width: 90%;
  }
  
  .submit input{
    width: 20vh;
    padding: 10px 4px;
    border: 1px solid black;
    text-align: center;
    font-size: 19px;

  }
  .form-group label {
    text-align: start;
    color: #050505;
    font-size: 1rem;
    font-weight: 600;
    width: 60%;
  }
  
  .form-group textarea {
    resize: vertical; /* Allow vertical resizing of textarea */
  }

  .signup-button1{
    background-image: linear-gradient(to right, #314755 0%, #26a0da  51%, #314755  100%);
   
    width: 20vh;
    padding: 10px 10px;
    float: right;
    margin-top: -8px;
  }
  .back-button{
  background-color: #f1440b;
  border-radius: 30px;
  padding: 0px 1px;
  width: 17vh;
  font-size: 15px;  
   color: white;

 }
 .join-buttons{
  display: flex;
  justify-content: space-between;
 }

  .social-buttons {
    display: flex;
    flex-direction: column;
    
    gap: 10px;
    text-align: center;
    width: auto;
    
    align-items: center;
  }
  
  .social-login-button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px 20px;
   background-color: transparent;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
      .social-login-button:hover {
        background-position: right center; /* change the direction of the change here */
        color: #fff;
        background-image: linear-gradient(to right, #314755 0%, #26a0da  51%, #314755  100%);
        text-decoration: none;
      }


      

.icon-field {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .icon-field input {
    flex: 1;
    margin-left: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .email-popup-content {
    display: flex;
    flex-direction: column;
  }
  
   .join-button{
    background-color: #f1440b;
    color: #fff;
    padding: 10px 3px;
    border-radius: 30px;
    border: none;
   width: 18vh;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 10px;

  }
  .join-button1{
    
  background-color:#26a0da;
  color: #fff;
  padding: 10px 3px;
  border-radius: 0px;
  border: none;
 width: 20vh;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
  }
  .email-popup-content .join-button:hover {
    background-color: #45a049;
  }
  
  /* Add more styles as needed */
  
  
  .email-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgb(2,0,36);
    background: -webkit-linear-gradient(to right, #9bc5c3, #616161);  
   background: linear-gradient(to right, #9bc5c3, #616161);  
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 500px;
    text-align: center;
    height: auto;
   padding-top: 50px;
    text-align: center;
    padding-bottom: 40px;
  }
  
  .email-popup .icon-field {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: chocolate;
    font-size: 24px;
  }
  
  .email-popup input {
    width: 100%;
    padding: 10px;
    margin-left: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }
  
  .email-popup .join-button {
    background-color: #3498db;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .email-popup .join-button:hover {
    background-color: #2980b9;
  }
  
  .email-popup .popup-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 18px;
    color: #666;
  }
 

  /* Media Queries for Tablets */
@media only screen and (max-width: 768px) {
    .popup-form {
      width: 80%; /* Adjusted width for tablets */
    }
  
    .social-buttons {
      width: 50%; /* Adjusted width for tablets */
    }
  
    .social-login-button {
      width: 100%; /* Adjusted width for tablets */
    }
  
    .icon-field input {
      width: 80%; /* Adjusted width for tablets */
    }
  
    .signup-button1 {
      width: 50%; /* Adjusted width for tablets */
    }
  }
  
  /* Media Queries for Mobiles */
  @media only screen and (max-width: 480px) {
    .popup-form {
      width: 90%; /* Adjusted width for mobiles */
    }
  
    .social-buttons {
      width: 70%; /* Adjusted width for mobiles */
    }
  
    .social-login-button {
      width: 100%; /* Adjusted width for mobiles */
    }
  
    .icon-field input {
      width: 90%; /* Adjusted width for mobiles */
    }
  
    .signup-button1 {
      width: 80%; /* Adjusted width for mobiles */
    }
  }