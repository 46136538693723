/* Blog Container */
.sb-container {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  
  padding-top: 100px;

 
}


/* Blog Content Container */
.blog-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Blog Left Section */
.blog-left {
  flex: 2.5;
}


/* Blog Card */
.blog-card {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  height: 600px;
  text-align: start;
  width: 850px;
}
.blog-card img{
  width: 800px;
  height: 380px;
  object-fit: cover;
}
.blog-card1{
  background-color:aliceblue;
  border: 1px solid #aba9a9;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 15px;
  
}


.blog-card h2 {
  font-size: 24px;
  margin: 0;
  color: #333;
}

.blog-card h5 {
  font-size: 20px;
  color: black;
  
}
.blog-card p{
  font-size: 18px;
  color: black;
  
  
  line-height: 1.3;
  
}


.blog-card h5:hover{
  
  color: #fe5900;
  transition-duration: 1.5s;

}
.Hov:hover{
  color: #fe5900;
  transition-duration: 1.5s;
  margin-top: 0px;
}

.blog-fake-img {
  background-color: #f0f0f0;
  width: 100%;
}

/* Blog Right Section */
.blog-right {
  flex: 1;
  width: auto;
}

/* Social Media Icons */
.blog-card i.fa {
  font-size: 24px;
  margin-right: 10px;
  color: #333;
}


.btn {
  background: blue;
    color: #fff;
  }
  .xt-blog-form {
    margin-top: 50px;
  }

  /* Add this CSS to your component's CSS file or style block */

.containe {
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.xt-blog-form {
  width: 100%;
  max-width: 300px;
}

.input-group {
  position: relative;
  margin-bottom: 15px;
}

.form-control {
  border-radius: 0;
  height: 40px;
 
  border: 1px solid rgb(211, 211, 211);
  width: 35vh;
}

.input-group-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-default {
  background-color: white;
  color: #fe5900;
  border-radius: 0;
  height: 40px;
  padding: 0 20px;
  margin-top: 20px;
  border: 1px solid rgb(211, 211, 211);
  width: 35vh;
}

/* Style for the heart icon */
.icon-bs {
  border: 1px solid white;
  background-color: #fe5900;
  color:black;
  font-size: 15px;
 margin-bottom: -10px;
  padding: 8px 10px;
  width: 6vh;
  height: 6vh;
}

.blog-card2 {

  background-color:aliceblue;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  text-align: start;
  width: auto;
}

.image-card{
  display: flex;
  
}
.image-card img{
 height: 70px;
} 

.image-card h6,p{
  margin-top: -2px;
 margin-left: 40px;
}


.btns {
  display: flex;
  flex-wrap: wrap;
  
}

.btns a{
  border: 1px solid rgb(209, 209, 209);
  background-color: white;
  padding: 5px 7px;
  margin-left: 5px;
  margin: 10px;
}
.btns a:hover{
   background-color: #fe5900;
   color: white;
   transition-duration: 1.4s;
}



.image-row1 {
  text-align: start;
  margin: 2px;
}



.image-containe img {
  max-width: 80px; /* Set the maximum width of the images as per your design */
  margin: 0 7px; /* Adjust the horizontal spacing between images */
}

.contain .xt-blog-form {
 
  max-width: 300px;
}

.contain .input-group {
  position: relative;
  margin-bottom: 15px;
}

.contain .form-control {
  border-radius: 0;
  height: 45px;
  padding: 4px 4px;
 
  border: 1px solid rgb(211, 211, 211);
  width: 45vh;
}

.contain .input-group-btn {
  display: flex;
 text-align: start;
  align-items: center;
}

.contain .btn-default {
  background-color: white;
  color: #fe5900;
  border-radius: 0;
  height: 44px;
  padding: 0 20px;
  margin-top: 20px;
  border: 1px solid;
  width: 43vh;
}

.contain h1{
  text-align: start;
}
.text-area1{
  width: 100%;
}
.comment-form-email input{
  width: 80%;
  padding: 9px 9px;
}
.comment-form-author input{
  width: 80%;
  padding: 12px 12px;
}
 
hr {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
/* For mobile devices */
@media (max-width: 600px) {
  .sb-container {
    padding-top: 80px; /* Adjust spacing as needed */
  }

  .blog-container {
    flex-direction: column;
    gap: 10px;
  }

  .blog-card,
  .blog-card1,
  .blog-card2 {
    height: auto;
    width: auto;
    
  background-color:aliceblue;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  text-align: start;
  width: auto;
  }

  .blog-card img {
    width: 100%; /* Adjust image width to fit smaller screens */
    height: auto; /* Maintain aspect ratio */
  }

  .form-control {
    width: 100%;
  }

  .input-group-btn {
    width: 100%;
    justify-content: flex-start;
  }

  .btn-default {
    width: 100%;
    margin-top: 10px;
  }

  .image-card img {
    height: 50px; /* Adjust image height for smaller screens */
  }

  .btns a {
    padding: 3px 5px;
    margin: 5px;
  }
  .comment-form-email input{
    width: 80%;
    padding: 9px 9px;
  }
  .comment-form-author input{
    width: 80%;
    padding: 12px 12px;
  }
}

/* For tablets and small desktops */
@media (min-width: 601px) and (max-width: 992px) {
  .sb-container {
    padding: 10px;
  }

  .blog-container {
    flex-direction: column;
    gap: 10px;
  }

  .blog-card,
  .blog-card1,
  .blog-card2 {
    height: auto;
    width: 90%;
  }

  .blog-card img {
    width: 100%; /* Adjust image width to fit smaller screens */
    height: auto; /* Maintain aspect ratio */
  }

  .form-control {
    width: 100%;
  }

  .input-group-btn {
    width: 100%;
    justify-content: flex-start;
  }

  .btn-default {
    width: 100%;
    margin-top: 10px;
  }

  .image-card img {
    height: 50px; /* Adjust image height for smaller screens */
  }

  .btns a {
    padding: 3px 5px;
    margin: 5px;
  }
  .comment-form-email input{
    width: 80%;
    padding: 9px 9px;
  }
  .comment-form-author input{
    width: 80%;
    padding: 12px 12px;
  }
}


/* Media queries for screens between 820px and 1180px */

@media (min-width: 821px) and (max-width: 1180px) {
  .sb-container {
    padding-top: 80px; /* Adjust spacing as needed */
  }

  .blog-container {
    flex-direction: column;
    gap: 10px;
  }

  .blog-card,
  .blog-card1,
  .blog-card2 {
    height: auto;
    width: 95%;
  }

  .blog-card img {
    width: 90%; /* Adjust image width to fit smaller screens */
    height: auto; /* Maintain aspect ratio */
  }

  .form-control {
    width: 100%;
  }

  .input-group-btn {
    width: 100%;
    justify-content: flex-start;
  }

  .btn-default {
    width: 100%;
    margin-top: 10px;
  }

  .image-card img {
    height: 50px; /* Adjust image height for smaller screens */
  }

  .btns a {
    padding: 3px 5px;
    margin: 5px;
  }

  .comment-form-email input {
    width: 80%;
    padding: 9px 9px;
  }

  .comment-form-author input {
    width: 80%;
    padding: 12px 12px;
  }
}