/* Footer Styles */
.footer {
    background: aliceblue;
     top: 60px;
      padding-bottom: 6px;
      margin-top: 50px;
    }
    
    .footerContent {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      margin-left: 30px;
      margin-bottom: 0px;
     
      font-weight: 500;
      flex-wrap: wrap;
    }
    
    .footerContent .contentBox {
      margin: 30px 0 15px 0;
      width: 300px;
      margin-left: 20px;
    }
    
      
   
   .footerContent .logo span {
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      background: -webkit-linear-gradient(90deg, rgba(244,67,54,1) 35%, rgba(156,39,176,1) 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 50px;
      font-weight: 500;
   
    }
    
    .footerContent .contentBox h3 {
      font-size: 20px;
      color: #f1440b;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
    
    .footerContent .contentBox ul li {
      list-style: none;
      margin: 13px 0;
      transition: 0.3s ease-in-out;
    }
    
    .footerContent .contentBox ul li a {
    color: rgb(12, 12, 12);
      text-decoration: none;
      transition: 0.3s ease-in-out;
      font-family: verdana;
    }
    
    .footerContent .contentBox ul li a:hover {
      color: #f1440b;
      text-decoration: underline;
      transition: 0.3s ease-in-out;
    }
    
    .footerContent .contentBox ul li:hover {
      transform: translateX(6px);
    }
    
    .footerContent .contentBox p.csupport {
      color: #fff;
      line-height: 1.5em;
      margin-bottom: 30px;
    }
    
    .footerContent .contentBox .callbtn {
      padding: 10px 10px;
      border: 2px solid #fff;
      border-radius: 30px;
     color: white;
      cursor: pointer;
      transition: 0.3s ease-in-out;
    }
    
    .footerContent .contentBox .callbtn:hover {
      color: #fff;
      background: #fe5900;
      cursor: pointer;
    }
    
    .footerContent .contentBox .callbtn:hover i {
      color: #f1440b;
      cursor: pointer;
    }
    
    .footerContent .contentBox a i {
      color: #fe5900;
      margin-right: 10px;
      font-size: 20px;
    }
    
    .footerContent .contentBox a span {
      color: #f1440b;
    }
    
    hr {
      border: 1px solid black;
    }
    
    .copyrights {
      padding: 0 240px;
      text-align: center;
    }
    
    .copyrights p {
      font-size: 18px;
      font-weight: 400;
      color: rgb(12, 12, 12);
      padding: 20px 0;
    }
  .social-icons2 {
    color: black;
   
   
  }
  .social-links1{
    padding-top: 0px;
    padding-left: 15px;
    color: #f1440b;
  }
 
  .social-links1 a {
    font-size: 22px;
    display: inline-block;
    background: #fff;
   
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 24px;
    transition: 0.3s;
    border: 1px solid  #f1440b;
  }

  .social-icons2:hover{
    color:  #f1440b;
    transition-duration: 1.4s;
  }  /* Media query for tablets (768px width and below) */
  @media (max-width: 768px) {
    .footerContent {
     
      margin-left: 20px;
    }
  
    .footerContent .contentBox {
      margin: 20px 0;
      width: 100%;
    }
  
    .footerContent .contentBox h3 {
      font-size: 18px;
      margin-bottom: 15px;
    }
  
    .footerContent .contentBox ul li {
      margin: 10px 0;
    }
  
    .footerContent .contentBox p.csupport {
      margin-bottom: 20px;
    }
  
    .footerContent .contentBox .callbtn {
      padding: 8px 8px;
      font-size: 14px;
    }
  
    hr {
      margin: 20px 0;
    }
  
    .copyrights {
      padding: 0 20px;
    }
  
    .copyrights p {
      font-size: 16px;
    }
  }
  
  /* Media query for mobile devices (480px width and below) */
  @media (max-width: 480px) {
    .footerContent {
      margin-left: 10px;
    }
  
    .footerContent .contentBox h3 {
      font-size: 16px;
    }
  
    .footerContent .contentBox ul li {
      margin: 8px 0;
    }
  
    .footerContent .contentBox p.csupport {
      font-size: 14px;
    }
  
    .footerContent .contentBox .callbtn {
      padding: 6px 6px;
      font-size: 12px;
    }
  
    hr {
      margin: 15px 0;
    }
  
    .copyrights p {
      font-size: 14px;
    }
  }