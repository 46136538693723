.AboutUs{
   
  padding: 20px;
  background-image:url('/public/Images/Courses-img/About1.webp');
  height: auto;
  padding-top: 40px;
  padding-bottom: 130px;
 
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
 
  }
  
  .AboutUs h1 {
     
      color: white;
    font-size: 2.6em;
   
    margin-bottom: 20px;
    padding-top: 80px;
    margin-left: 50px;
    letter-spacing: 2px;
    font-weight: 600;
    
    
  }
  
  .AboutUs p{
    font-size: 2.2em;
    letter-spacing: .8px;
   color: rgb(255, 255, 255);
    margin-top: 10px;
    padding-left: 30px;
    padding-top: 30px;
    font-weight: 400;
    line-height: 1.7em;
    margin-right: 40px;
  }
  .AboutUs strong{
      color: rgb(255, 147, 71);
      font-size: 48px;
      font-weight: 600;
  }

  
 
/* Media Queries */
@media only screen and (max-width: 768px) {
.AboutUs {
  padding-top: 80px;
  padding-bottom: 80px;
}

.AboutUs h1 {
  font-size: 2em;
  margin-left: 20px;
  padding-top: 80px;
}

.AboutUs p {
  font-size: 1.5em;
  padding-left: 20px;
  padding-top: 20px;
  margin-right: 20px;
}

.AboutUs strong {
  font-size: 36px;
}
}

@media only screen and (max-width: 480px) {
.AboutUs h1 {
  font-size: 1.8em;
  margin-left: 10px;
  padding-top: 60px;
}

.AboutUs p {
  font-size: 1.2em;
  padding-left: 10px;
  padding-top: 10px;
  margin-right: 10px;
}

.AboutUs strong {
  font-size: 24px;
}
}