div .Course-Blog{
  text-align: Center;
  padding: 20px;
  background-image:url(/public/Images/BG-imgs/Bg-Body.png);
  height: 65vh;
  padding-top: 60px;
  margin-bottom: 50px;
  /* font-family: 'Times New Roman', Times, serif; */
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  }
  
  .Course-Blog h1 {
    font-size: 4.2em;
    color: white;
    margin-bottom: 20px;
    padding-top: 100px;
   
    letter-spacing: 2px;
    font-weight: 600;
  }
  
  .Course-Blog h4 {
    font-size: 1.5em;
    color: white;
    margin-top: 10px;
    
    padding-top: 10px;
    font-weight: 400;
  }
  
  .Course-Blog a {
    color: white;
    font-size: 22px;
    text-decoration: none; 
  }
  h4 span {
    margin: 0 10px;
    color: #777;
  }