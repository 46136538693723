.Testing-container {
  display: flex;
  align-items: center;
  padding: 80px;
}

.image-Testng,
.content-Testing {
  flex: 1;
}

.image-Testng img {
  width: 90%;
  height: auto;
  margin-left: 50px;
}

.content-Testing {
  margin: 0px;
}

.content-Testing h2 {
  font-size: 40px;
  font-weight: 500;
}

.content-Testing p {
  font-size: 21px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.1px;
}

.content-Testing span {
  font-weight: 400;
  font-size: 28px;
  color: #fe5900;
}

/* Media queries for responsive design */

@media (max-width: 1024px) {
  .Testing-container {
      flex-direction: column;
      padding: 80px;
  }

  .image-Testng img {
      margin-left: 0;
      margin-top: 20px;
  }

  .content-Testing h2 {
      font-size: 30px;
  }

  .content-Testing p {
      font-size: 21px;
      font-weight: 300;
      width: fit-content;
      padding-left: 0;
      margin-left: 0;
  }

  .content-Testing span {
      font-size: 18px;
  }

  /* Enhance font rendering for better clarity */
  .content-Testing * {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
}

/* Media queries for smaller mobile screens */

@media (max-width: 768px) {
  .Testing-container {
      flex-direction: column;
      padding: 20px;
  }

  .image-Testng img {
      margin-left: 0;
      margin-top: 20px;
  }

  .content-Testing h2 {
      font-size: 30px;
  }

  .content-Testing p {
      font-size: 21px;
      line-height: 1.5;
      font-weight: 300;
     }

  .content-Testing span {
      font-size: 18px;
  }

  /* Enhance font rendering for better clarity */
  .content-Testing * {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
}

/* Media queries for smaller mobile screens */

@media (max-width: 480px) {
  .Testing-container {
      padding: 10px;
  }

  .content-Testing h2 {
      font-size: 20px;
  }

  .content-Testing p {
      font-size: 16px;
      font-weight: 400;
  }

  .content-Testing span {
      font-size: 16px;
  }

  /* Enhance font rendering for better clarity */
  .content-Testing * {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
}