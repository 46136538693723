.chat-container1 {
    position: relative;
  }
  
  .chat-label {
    position: fixed;
    bottom: 20px;
    background-color: #ec5a29;
    color: white;
    padding: 12px;
    cursor: pointer;
    transition: right 0.5s;
    font-size: 500;
  }
  
  
    .chat-window1 {
        position: fixed;
        bottom: 80px;
        right: 10px;
        width: 300px;
        height: 330px;
        background-color: whitesmoke; /* Black background with 70% opacity */
        padding: 10px;
        overflow-y:scroll;
        /* overflow-y: hidden; */
      }
  
  
  .input-area {
    position: fixed;
    bottom: 20px;
    right: 10px;
    
  
  }
  .input-area input{
   height: 35px;
   border: 1px solid black;
  }
  .message {
    margin-bottom: 10px;
    align-items: center;
  }
  
  .customer {
    background-color: #a3cef1;
    color:black;
    width: auto;
    height: 30px;
    font-size: 20px;
    font-weight: 400;
    font-family:Georgia, 'Times New Roman', Times, serif;
     
  }
  
  
  .owner {
    background-color: #f1440b;
    color: white;
    width: auto;
    padding: 0px 0px;
    border-radius: none;
  }
  .message.bot {
    background-color: #d3ffe7;
   color: black;
   font-weight: 500;
   font-size: 18px;
   font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  }

