.about {
  position: relative;
  width: 100%;
  
  background:white;
  display: flex;
  
  padding-top: 20px;

 align-items: center;
}


.outcomes{
  padding-top: 20px;
 color: black;
  font-size: 36px;
  text-align: center;
}
.about .imageBox img {
  width: 100%;
 height: auto;
}

.about .contentBox {
  width: 50%;
  padding-left: 150px;
  margin-right: 70px;
}
.outcomes{
  text-align: start;
}

.about .contentBox .capText {
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: 300;
  color: #fe5900;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.about .contentBox .bigTitle {
  font-size: 3em;
  line-height: 1em;
  color: #fe5900;
  margin-bottom: 30px;
  font-weight: 500;
}

.about .contentBox p.text {
  
  color: #10285d;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 15px;
  font-weight: normal;
  width: 510px;
}

.about .contentBox .btn {
  font-size: 1em;
  color: #fff;
  background: #fe5900;
  display: inline-block;
  padding: 20px 50px;
  margin-top: 30px;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  border-radius: 50px;
  box-shadow: 0 12px 30px rgba(134, 47, 0, 0.2);
  transition: 0.5s;
}
.about .custom-p {
 
  color:black;
  font-size: 19px;
  line-height: 30px;
  margin-bottom: 15px;
  font-weight: normal;
  width: 510px;
}
.custom-h2 {
 
 margin-bottom: 10px; 

 font-weight: 500;
 font-size: 2.8em;
 line-height: 1.4em;
 letter-spacing: 1px;
 color:black;
 
 width: 490px;
}
.icon-item {
font-size: 28px;
font-weight: 300;
color: #fe5900;
}
.about .contentBox .btn:hover {
  background: #fff;
  color: #581f00;
  /* background-image: linear-gradient(to top, #581f00, transparent); */
}

.imageBox {
  position: relative;
  animation: moveUpDown 5s infinite linear;
}


.about .icon-item p{

  font-family: "Raleway", sans-serif;
  color: black;
  font-size: 19px;
  
 line-height:1.5em ;
  
  width:80%;

}
@keyframes moveUpDown {
  0% {
    top: 0;
  }
  50% {
    top: 50px; /* Adjust the distance the image moves down */
  }
  100% {
    top: 0;
  }
}

@media (max-width: 1024px) {
  .about {
      flex-direction: column; /* Stack the items vertically on smaller screens */
  }

  .about .contentBox {
      /* Adjust the width for smaller screens */
      padding-left: 50px; /* Adjust the padding for smaller screens */
      margin-right: 0px; /* Adjust the margin for smaller screens */
  }

  .about .imageBox img {
      width: 100%; 
  }

  
  .outcomes {
      font-size: 28px; 
      width: 100%;
  }

  .icon-item {
      font-size: 24px; 
      width: 100%;
      max-width: 500px;
  }
}


/* Media Queries for Tablets */
@media only screen and (max-width: 768px) {

.about {
  padding-top: 10px; 
  flex-direction: column;
}

.about .contentBox {
    width: 80%; /* Adjusted width for tablets */
}

}
@media only screen and (max-width: 480px) {
  .about {
    padding-top: 10px; /* Adjust the padding for smaller screens */
    flex-direction: column; /* Stack the items vertically on smaller screens */
  }

  .about .contentBox {
    width: 90%; /* Adjusted width for mobile screens */
    padding-left: 20px; /* Adjust the padding for smaller screens */
    margin-right: 0; /* No margin for smaller screens */
  }

  .about .imageBox img {
    width: 90%; 
  }

  .custom-h2 {
    width: 90%; 
  }

  .custom-p {
    width: 100%;
    max-width: 380px;
    text-align: start;
    margin-left: -0px;
  }

  .icon-item p {
    width: 100%; 
  }

  .icon-item {
    font-size: 20px; /* Adjust the font size for smaller screens */
  }

  .outcomes {
    font-size: 24px; /* Adjust the font size for smaller screens */
  }
}