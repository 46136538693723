

.about-section {
    text-align: start;
   margin-left: 100px;
    margin-right: 80px;
  }
  
  #about-image {
    max-width: 100%;
    height: auto;
    padding-top: 30px;
  }
  
  .about-content h2{
    font-size: 2.7em;
    
  }
  .about-content h4{
  font-size: 2.3em;

  font-weight: 400;
  margin-top: -30px;
  }
  
  

/* Media Queries for Tablets and Mobiles */
@media (max-width: 768px) {
    .about-section {
      margin-left: 20px;
      margin-right: 20px;
    }
  
    .about-content h2 {
      font-size: 2em;
    }
  
    .about-content h4 {
      font-size: 1.8em;
      margin-top: -20px;
    }
  }
  
  @media (max-width: 576px) {
    .about-section {
      margin-left: 10px;
      margin-right: 10px;
    }
  
    .about-content h2 {
      font-size: 1.8em;
    }
  
    .about-content h4 {
      font-size: 1.6em;
      margin-top: -10px;
    }
  }