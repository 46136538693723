.container-Ui {
  display: flex;
 align-items: center;
 
  padding: 20px;
  padding-top:80px ;
  flex-wrap: wrap;
  
}

.container-Ui .left {
  text-align: start;
  padding-top: 30px; 

}

.container-Ui .right {
 margin-right: 40px;
 margin-left: 120px;
}

.container-Ui .right img {
  /* width: 100%;  */
  max-width: 530px;
  height:410px;
 margin-left: 60px;
 
}
.strong{
  text-transform: capitalize;
  color: #f1440b;
  font-size: 32px;
}

.container-Ui p {
 
  color:black;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 25px;
  font-weight: 400;
  text-align: start;
  width: 630px;
}
.container-Ui h2{
  margin-bottom: 10px; 
  font-weight: 500;
  font-size: 2.1em;
  line-height: 1.0em;
  letter-spacing: .5px;
  color:black;
  margin-left: 45px;
  width: 540px;
}
.container-Ui h5{
  margin-bottom: 10px; 
 
  font-weight: 400;
  font-size: 2em;
  line-height: 1.0em;
  letter-spacing: .5px;
  width: 500px;
}

@media only screen and (max-width: 768px) {
  .container-Ui {
    flex-direction: column;
    padding-top: 20px; 
    
  }

  .container-Ui .left {
    padding-top: 20px; 
    
  }

  .container-Ui .right {
    margin-right: 0;
    margin-bottom: 20px; 
    padding-left: 0px;
    margin-left: 0px;
  }

  .container-Ui .right img {
    width: 100%;
    max-width: 550px;
    padding-left: 0px;
    margin-left: 0px;
    height: auto;
    margin-left: 0;
  }

  .container-Ui p {
    width: 100%;
    font-size: 18px;
    font-weight: 400;
  }

  .container-Ui h2 {
    margin-left: 0;
    width: 100%;
  }
}



/* Media Queries */
@media screen and (max-width: 1180px) {
.container-Ui .left {
  width: 100%;
  text-align: center;
}

.container-Ui .right {
  margin-right: 0;
  margin-bottom: 20px;
}

.container-Ui .right img {
  width: 100%;
  max-width: 80%;
  height: auto;
}

.container-Ui h2,
.container-Ui p,
.container-Ui h5 {
  width: 100%;
  margin-left: 0;
}
}

@media screen and (max-width: 820px) {
.container-Ui {
  padding-top: 30px;
}

.container-Ui .left {
  width: 100%;
  text-align: center;
}

.container-Ui .right {
  margin-right: 0;
  margin-bottom: 20px;
}

.container-Ui .right img {
  width: 100%;
  max-width: 80%;
  height: auto;
}

.container-Ui h2,
.container-Ui p,
.container-Ui h5 {
  width: 100%;
  margin-left: 0;
}
}